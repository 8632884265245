.App {
  text-align: center;
}

.Content {
  /* text-align: left;
  max-width: -webkit-calc(900px - (30px * 2));
  max-width: calc(900px - (30px * 2)); */
  padding-right: 30px;
  padding-left: 30px;
  margin: 24px auto;
}

::selection {
  background: #4f91bb;
  color: #fcfcfc;
}

a {
  color: #ce1b1b;
  text-decoration: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
